@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --whiteColor: #fff;
  --blackColor: #000;
  --primaryFont: "Roboto Condensed", sans-serif;
  --yellow: #f0c94a;
  --blackText: #12121b;
  --lightYellow: rgb(252, 244, 220);
  --lightgray: #575757;
  --shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  --blackLight: #1a1f1f;
  --blackLighter: #2a2f2f;
  --grayThin: #efeeee;
  --gray500: #d8d7d7;
  --border: 1px solid #3c3c3c;
  --borderColor: #3c3c3c;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

.otpWidth {
  width: 50px !important;
  height: 50px;
  border: 1.5px solid var(--borderInput);
  border-radius: 8px;
  background-color: var(--lightYellow);
}

.otpWidth:focus-visible {
  outline: 1px solid var(--yellow);
  background-color: var(--lightYellow);
}

.otpWidth:focus {
  border: 1.5px solid var(--yellow) !important;
  border-radius: 8px;
}

.otpContainer {
  justify-content: space-between;
}

.activeOtp {
  outline: 1.5px solid var(--yellow);
  background-color: var(--yellow);
}

.rdt_TableRow:hover {
  background-color: var(--lightYellow) !important;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--yellow);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiSelect-root {
  background-color: var(--blackLighter) !important;
  color: var(--whiteColor) !important;
  border-radius: 1rem !important;
  outline: none !important;
  border: var(--border);
  font-size: 14px !important;
  font-family: var(--primaryFont) !important;
  text-align: left;
}

.active {
  color: var(--yellow) !important;
  background-color: var(--blackLighter) !important;
}

.MuiTabs-root {
  border-radius: 20px 20px 0px 0px;
}

.MuiButtonBase-root-MuiPaginationItem-root {
  border: 1px solid white !important;
  color: white !important;
}

.MuiPaginationItem-root {
  color: white !important;
}

.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  border: 2px solid var(--yellow) !important;
  color: var(--yellow) !important;
  background-color: var(--blackLighter) !important;
}

svg {
  color: white !important;
}

/* .css-3vnhty {
  color: white !important;
  background-color: var(--blackLight) !important;
  border: var(--border);
  border-radius: 20px !important;
  max-width: 500px;
} */

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: black !important;
  background: var(--yellow) !important;
}

/* .css-1gsv261 {
  border-bottom: none !important;
} */
